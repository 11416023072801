<template>
  <div class="pb-5">
    <h4>{{ name }}</h4>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="8">
            <v-text-field
              label="Introduction Title"
              v-model="form.intro_title"
              :rules="[getRules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="default px-3 py-2" outlined>Write introduction about the hotel.</v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-textarea label="Description" v-model="form.description" :rules="[getRules.required]"></v-textarea>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="default px-3 py-2" outlined>Write description about the hotel.</v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  ref="checkInTimeMenu"
                  v-model="checkInTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.checkin_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="form.checkin_time" label="Check in Time" readonly v-bind="attrs" v-on="on">
                      <template slot="append">
                        <v-btn icon v-if="form.checkin_time" @click="form.checkin_time = null">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn icon @click="checkInTimeMenu = true">
                          <v-icon>mdi-clock-time-four-outline</v-icon>
                        </v-btn>
                      </template></v-text-field
                    >
                  </template>
                  <v-time-picker
                    v-if="checkInTimeMenu"
                    ampm-in-title
                    v-model="form.checkin_time"
                    full-width
                    @click:minute="$refs.checkInTimeMenu.save(form.checkin_time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="checkOutTimeMenu"
                  v-model="checkOutTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="form.checkout_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.checkout_time"
                      label="Check out Time"
                      append-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    >
                      <template slot="append">
                        <v-btn icon v-if="form.checkout_time" @click="form.checkout_time = null">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn icon @click="checkOutTimeMenu = true">
                          <v-icon>mdi-clock-time-four-outline</v-icon>
                        </v-btn>
                      </template></v-text-field
                    >
                  </template>
                  <v-time-picker
                    v-if="checkOutTimeMenu"
                    v-model="form.checkout_time"
                    ampm-in-title
                    full-width
                    @change="$refs.checkOutTimeMenu.save(form.checkout_time)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="default px-3 py-2" outlined>What check in/out timings are for your hotel?</v-card>
          </v-col>
          <v-col cols="12" md="8" class="py-0 my-0 text--primary">
            <v-checkbox
              class="py-0 my-0"
              v-model="form.vendor_specifications.is_reception_always_available"
              label="24h Reception"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-row class="mt-2">
          <v-col cols="12" md="8">
            <h5>Primary Contact Booking</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="3">
                <div class="justify-end mx-auto d-flex align-end custom-avatar">
                  <v-img
                    v-if="form.profile_picture"
                    contained
                    :src="'/' + form.profile_picture"
                    max-width="100px"
                    height="100px"
                    class="mx-auto border rounded-circle"
                  >
                  </v-img>

                  <v-img v-else contained max-width="100px" height="100px" class="mx-auto border rounded-circle">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0 secondary white--text" align="center" justify="center">
                        <v-icon size="80" color="white">mli-neutral-avatar</v-icon>
                      </v-row>
                    </template>
                  </v-img>

                  <v-btn
                    fab
                    absolute
                    x-small
                    color="primary"
                    class="custom-avatar-button"
                    @click="$refs.profilePicture.click()"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <input
                    style="display: none"
                    type="file"
                    ref="profilePicture"
                    accept="image/*"
                    @change="uploadContactImage($event, 'primary')"
                  />
                </div>
              </v-col>
              <v-col cols="12" md="9" class="py-0 my-0">
                <v-row>
                  <v-col cols="12" md="12" class="py-0 my-0">
                    <div class="d-flex">
                      <v-select
                        style="max-width: 80px;"
                        class="mr-1"
                        label="Title"
                        v-model="form.person_title"
                        :items="titles"
                      ></v-select>
                      <v-text-field label="Full Name" v-model="form.full_name"></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0 my-0">
                    <v-text-field label="Job Title" v-model="form.job_title"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8" class="py-0 my-0">
            <v-text-field label="Contact Email" v-model="form.email" :rules="[getRules.email]"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="py-0 my-0">
          <v-col cols="12" md="8" class="py-0 my-0 mt-2">
            <v-text-field label="Phone Number" v-model="form.phone"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="my-0 py-0">
          <v-col cols="12" md="8" class="my-0 py-0">
            <v-checkbox v-model="isSecondaryContact" label="Add secondary contact"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="isSecondaryContact">
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" md="3">
                <div class="justify-end mx-auto d-flex align-end custom-avatar">
                  <v-img
                    v-if="form.secondary_profile_picture"
                    contained
                    :src="'/' + form.secondary_profile_picture"
                    max-width="100px"
                    height="100px"
                    class="mx-auto border rounded-circle"
                  >
                  </v-img>

                  <v-img v-else contained max-width="100px" height="100px" class="mx-auto border rounded-circle">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0 secondary white--text" align="center" justify="center">
                        <v-icon size="80" color="white">mli-neutral-avatar</v-icon>
                      </v-row>
                    </template>
                  </v-img>

                  <v-btn
                    fab
                    absolute
                    x-small
                    color="primary"
                    class="custom-avatar-button"
                    @click="$refs.secondaryProfilePicture.click()"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <input
                    style="display: none"
                    type="file"
                    ref="secondaryProfilePicture"
                    accept="image/*"
                    @change="uploadContactImage($event, 'secondary')"
                  />
                </div>
              </v-col>
              <v-col cols="12" md="9" class="py-0 my-0">
                <v-row>
                  <v-col cols="12" md="12" class="py-0 my-0">
                    <div class="d-flex">
                      <v-select
                        style="max-width: 80px;"
                        class="mr-1"
                        label="Title"
                        v-model="form.secondary_person_title"
                        :items="titles"
                      ></v-select>
                      <v-text-field label="Full Name" v-model="form.secondary_full_name"></v-text-field>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="py-0 my-0">
                    <v-text-field label="Job Title" v-model="form.secondary_job_title"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="isSecondaryContact">
          <v-col cols="12" md="8" class="py-0 my-0">
            <v-text-field label="Contact Email" v-model="form.secondary_email" :rules="[getRules.email]"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="isSecondaryContact" class="py-0 my-0 mt-2">
          <v-col cols="12" md="8" class="py-0 my-0">
            <v-text-field label="Phone Number" v-model="form.secondary_phone" :rules="[getRules.number]"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mr-3 mb-5 mt-5">Continue</v-btn>
    <v-btn
      color="secondary"
      class="mr-1 float-right mb-5 mt-5"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Description",
  props: ["name", "index", "form"],
  components: {},
  data: () => ({
    titles: ["Mr.", "Mrs.", "Miss", "Ms", "Mx"],
    checkInTimeMenu: false,
    checkOutTimeMenu: false,
    isSecondaryContact: false,
    seatingArrangements: [
      {
        name: "Theatre",
        icon: "mdi mdi-theater"
      },
      {
        name: "Banquet",
        icon: "mdi mdi-office-building-outline"
      },
      {
        name: "U-Shape",
        icon: "mdi mdi-alpha-u-box"
      },
      {
        name: "Cabaret",
        icon: "mdi mdi-account-music"
      },
      {
        name: "Board",
        icon: "mdi mdi-bulletin-board"
      }
    ],
    hotelSpaces: [
      {
        space_height_in_meters: "",
        event_space_size: "",
        floor_condition: "",
        hotel_height: "",
        max_seating: "",
        max_standing: "",
        is_day_light: "",
        is_removeable: false
      }
    ]
  }),
  mounted() {
    if (this.getIsVendorUpdate && this.form.type === "hotel") {
      if (
        this.form &&
        (this.form.secondary_full_name ||
          this.form.secondary_job_title ||
          this.form.secondary_email ||
          this.form.secondary_phone)
      ) {
        this.isSecondaryContact = true;
      }
    }
  },
  computed: {
    ...mapGetters(["getRules", "getNewHotelSpace", "getIsVendorUpdate", "getVendor", "getAuthToken"])
  },
  methods: {
    uploadContactImage(event, type = "primary") {
      const files = event.target.files;
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        formData.append("files[" + i + "]", file);
      }
      formData.append("type", "contacts");
      axios
        .post("/api/v1/hotels/upload-gallery-images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + this.$store.getters.getAuthToken
          }
        })
        .then(response => {
          if (response.status === 200) {
            let url = response.data.urls[0].replace("public/", "storage/");

            if (type === "primary") {
              this.form.profile_picture = url;
            } else if (type === "secondary") {
              this.form.secondary_profile_picture = url;
            }
          }
        });
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("hotelNextStep", this.index);
    },
    addSpace() {
      this.form.hotel_spaces.push(new Form(this.getNewHotelSpace));
    }
  },
  watch: {
    isSecondaryContact: function() {
      if (this.isSecondaryContact === false) {
        this.form.secondary_full_name = null;
        this.form.secondary_job_title = null;
        this.form.secondary_email = null;
        this.form.secondary_phone = null;
      }
    },
    "form.checkin_time": function(v) {
      this.$refs.checkInTimeMenu.save(v);
    },
    "form.checkout_time": function(v) {
      this.$refs.checkOutTimeMenu.save(v);
    }
  }
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}
.pointer {
  cursor: pointer;
}
.custom-avatar-icon {
}
.custom-avatar {
  position: relative;
}
.custom-avatar-button {
  right: 30px;
}
</style>
